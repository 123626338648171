import { Link } from "gatsby";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSpring, animated } from "react-spring";

import smileWhite from "../images/icons/white-smiley.svg";
import smileYellow from "../images/icons/schmiley-yellow.svg";
import useWindowSize from "../hooks/useWindowSize";

const Header = ({ reverse = false }) => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const { width } = useWindowSize();
  const [opacityAnimation, setOpacityAnimation] = useSpring(() => ({
    opacity: 0,
  }));

  useEffect(() => {
    if (isNavOpen) {
      setOpacityAnimation({ opacity: 1 });
    } else {
      setOpacityAnimation({ opacity: 0 });
    }
  }, [isNavOpen]);

  return (
    <Container
      onMouseOver={() => (width > 800 ? setIsNavOpen(true) : undefined)}
      onMouseLeave={() => (width > 800 ? setIsNavOpen(false) : undefined)}
    >
      <SmileyBtn>
        <ImgContainer>
          <Smiley
            big={isNavOpen}
            src={
              reverse || (isNavOpen && width < 800) ? smileWhite : smileYellow
            }
            onClick={() => setIsNavOpen(!isNavOpen)}
          />
        </ImgContainer>
      </SmileyBtn>
      <StyledHeader
        style={{
          ...opacityAnimation,
          ...(!isNavOpen && width < 800 ? { display: "none" } : {}),
        }}
      >
        <Nav reverse={!!reverse}>
          <Link onClick={() => setIsNavOpen(false)} to="/">
            Home
          </Link>
          <Link onClick={() => setIsNavOpen(false)} to="/work">
            Work
          </Link>
          <Link onClick={() => setIsNavOpen(false)} to="/about">
            About
          </Link>
          <Link onClick={() => setIsNavOpen(false)} to="/contact">
            Contact
          </Link>
        </Nav>
      </StyledHeader>
    </Container>
  );
};

export default Header;

const ImgContainer = styled.div`
  height: 100px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Smiley = styled.img`
  margin: 0;
  ${({ big }) =>
    big
      ? `
height: 70px;
    transition: 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    `
      : `
  height: 50px;
  transition: 0.2s ease-in-out;
    `}
`;

const SmileyBtn = styled.div`
  z-index: 3;
`;

const Nav = styled.div`
  margin-right: 100px;
  display: grid;
  border-radius: 12px;
  grid-column-gap: 1em;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 1em;
  a {
    border-radius: 20px;
    color: ${({ reverse, theme: { blue } }) => (!reverse ? blue : "#fff")};
    border: 2px solid transparent;
    transition: all 0.2s ease-in-out;
    padding: 5px 10px;
  }

  @media only screen and (min-width: 800px) {
    a:hover {
      border: 2px solid
        ${({ reverse, theme: { blue } }) => (!reverse ? blue : "#fff")};
      transition: all 0.2s ease-in-out;
      padding: 5px 10px;
      background-color: ${({ reverse, theme: { blue } }) =>
        reverse ? blue : "#fff"};
    }
  }

  @media only screen and (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-row-gap: 3em;
    margin-right: 0;
    grid-template-columns: 1fr;
    a {
      text-align: center;
      font-size: 2em;
      color: #fff;
    }
  }
`;

const Container = styled.div`
  position: fixed;
  top: 2em;
  right: 2em;
  z-index: 2;

  @media only screen and (max-width: 800px) {
    top: 0;
    right: 0;
  }
`;

const StyledHeader = styled(animated.div)`
  z-index: -1;
  position: absolute;
  display: flex;
  align-items: center;
  height: 100%;
  top: 0;
  right: 0;

  @media only screen and (max-width: 800px) {
    min-height: 100vh;
    width: 100vw;
    background-color: ${({ theme: { blue } }) => blue};
    position: fixed;
    flex-direction: column;
    color: #fff;
    align-items: center;
    justify-content: center;
  } ;
`;
